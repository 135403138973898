<template>
    <div class="site-header">
        <div class="site-header__container">
            <nuxt-link class="site-header__logo" title="The North Face" :to="'https://www.thenorthface.co.uk/'" target="_blank">
                <img src="~/assets/img/logo-tnf.svg" height="42" width="89" alt="The North Face" />
            </nuxt-link>
            <div class="site-header__navigation" v-if="user && store.user && store.progress.completed_profile">
                <nuxt-link class="site-header__dashboard" :title="menu.back_to_dashboard" to="/dashboard">
                    <img src="~/assets/img/icon-compass.svg" height="24" width="24" />
                </nuxt-link>
                <nuxt-link class="site-header__menu" :title="menu.open_menu" @click="openMenuDrawer">
                    <img src="~/assets/img/icon-menu.svg" height="24" width="24" />
                </nuxt-link>

                <div class="site-header-drawer" :class="{ 'site-header-drawer--visible' : showMenu }">
                    <div class="site-header-drawer__container">
                        <SVGIconClose @click="closeMenuDrawer"/>
                        <div class="site-header-drawer__content">
                            <p class="site-header-drawer__title" v-html="menu.title"></p>
                            <p class="site-header-drawer__body" v-html="menu.body"></p>
                            <nuxt-link class="button" :to="menu.button.link" :title="menu.button.text" @click="closeMenuDrawer"><span v-html="menu.button.text"></span></nuxt-link>
                        </div>
                        <button class="button button--grey button--logout" v-html="menu.logout_button.text" :title="menu.logout_button.text" @click="logOut"></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    @import "@sass-ui/site-header.scss";
    @import "@sass-ui/site-header-drawer.scss";
</style>

<script setup>
    const { menu } = useGetData();
    const supabase = useSupabaseAuthClient();
    const user = useSupabaseUser();
    const store = useTNFStore();

    const showMenu = ref(false);

    let lastScroll = 0;
    
    onMounted(() => {
        document.body.addEventListener("scroll", fixHeader, false);
    });

    const openMenuDrawer = () => {
        showMenu.value = true;
    }

    const closeMenuDrawer = () => {
        showMenu.value = false;
    }

    const logOut = async () => {
        showMenu.value = false;

        try {
            let { error } = await supabase.auth.signOut();

            if ( error ) {
                throw error;
            } else {
                store.logoutUser();
                reloadNuxtApp();
            }


        } catch ( error ) {
            console.error( error.message );
        }
    }

    const fixHeader = () => {
        const scrollUp = "scroll-up";
        const scrollDown = "scroll-down";
        const currentScroll = document.body.scrollTop;

        if (currentScroll <= 0) {
            document.body.classList.remove(scrollUp);
            document.body.classList.remove(scrollDown);
            return;
        }
        if (currentScroll > lastScroll) {
            document.body.classList.remove(scrollUp);
            document.body.classList.add(scrollDown);
        } else if ( currentScroll < lastScroll ) {
            document.body.classList.add(scrollUp);
            document.body.classList.remove(scrollDown);
        }

        lastScroll = currentScroll;
    };

</script>